.GamesPage {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.description {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  justify-content: center;
  flex: .5;
  margin-bottom: 2rem;
}

.title {
  font-weight: bold;
  font-size: 2rem;
  text-align: left;
  margin-bottom: .5rem;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2rem;
  max-width: 50%;
  box-shadow: 0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);
}