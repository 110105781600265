.LegalModal {
  width: 75vw;
  height: 75vh;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.text {
  align-items: center;
  text-align: center;
  align-self: center;
  justify-self: center;
}
