.SoftwareModal {
  width: 80vw;
  height: 80vh;
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;

  display: flex;
}

.voteButtons {
  display: flex;
  flex-direction: row;
  padding: 2rem;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;

  flex: 1;
}
.rightColumn {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  flex: 4;
}

.icon {
  font-size:20px;
  margin: .5rem;
}

.title {
  font-size: 40px;
  font-weight: bold;
}