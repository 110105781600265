.images {
  width: 10rem;
  height: 10rem;
  border-radius: 5px;
  border: 1px solid;
  transition: all .2s;

  &:hover {
    width: 10.1rem;
    height: 10.1rem;
  }
}