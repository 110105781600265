.Footer {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
}

.leftItems {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.rightItems {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.links {
  font-size: 15px;
  padding: 1rem;
  color: #fff;
  border: none;
  background-color: transparent;

  &:hover {
    color: #c7c7c7;
  }
}

.title {
  padding: 1rem;
  color: #fff;
}
