.Header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  justify-items: space-between;
}

.leftItems {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.rightItems {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.links {
  font-size: 20px;
  padding: 1rem;
  color: #fff;
  border: none;
  background-color: transparent;
  transition: all 0.2;

  &:hover {
    color:#c7c7c7 
  }
}

.title {
  padding: 1rem;
}
