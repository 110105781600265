.App {
  text-align: center;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 7vh 1fr 5vh;
  grid-template-areas: "header" "main" "footer";
}

.header {
  grid-area: header;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main {
  grid-area: main;
  padding: 1rem;
  overflow: auto;
}

.footer {
  grid-area: footer;
  display: flex;
  background-color: hsl(0, 0%, 34%);
}