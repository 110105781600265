.AppCarousel {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
}
.title {
  font-weight: bold;
  font-size: 2rem;
  text-align: left;
}
.appContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.horizontalLine {
  color: black;
  width: 200%;
}
.imageButton {
  // width: 10rem;
  // height: 10rem;
  border-radius: 5px;
  background-color: transparent;
  outline: thin;
  border: none;
}

.info {
  margin-left: 0.5rem;
  color: #828282;
  position: relative;
  display: inline-block;

  /* Tooltip text */
  .tooltiptext {
    visibility: hidden;
    opacity: 0;
    width: 120px;
    background-color: #2e3037;
    padding: 0.4rem;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    transition: opacity .4s;
    -webkit-transition-property: opacity;
    -webkit-transition-duration: .4s;
    transition-property: opacity;
    transition-duration: .4s;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    left: 130%;

    // &::after {
    //   content: " ";
    //   position: absolute;
    //   top: 10%;
    //   right: 100%; /* To the left of the tooltip */
    //   margin-top: -5px;
    //   border-width: 5px;
    //   border-style: solid;
    //   border-color: transparent black transparent transparent;
    // }
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  &:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  align-content: center;
}
